/* eslint-disable no-console */

$(function() {
  $('.featureFlagReport select#for, #and_audit_all_domains').on('change', function() {
    var flag = $('.featureFlagReport select').val();
    var audit = !!$('#and_audit_all_domains:checked').length;

    if (!_.isUndefined(blist.feature_flags[flag])) {
      var andAudit = audit && '&and_audit_all_domains=true' || '';
      window.location.assign(
        `/internal/feature_flag_report?for=${flag}${andAudit}`);
    }
  });
});
